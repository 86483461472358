<template>
    <b-table-simple responsive fixed>
        <thead>
            <tr>
                <th width="3%">
                    <Check 
                        v-model="masterCheck" 
                        @change="checked => $emit('handlerMasterSelect', checked)"
                    />
                </th>
                <th><Ellipsis>Convênio</Ellipsis></th>
                <th><Ellipsis>Inflator/Deflator</Ellipsis></th>
                <th><Ellipsis>Preço unidade</Ellipsis></th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(exception, index) in exceptions" :key="index">
                <td>
                    <Check 
                        :value="selecteds.includes(exception.id)" 
                        @input="checked => !checked && masterCheck ? (masterCheck = false) : null"
                        @change="$emit('handlerSelect', exception)" 
                    />
                </td>
                <td>{{ exception.clinic_health_plan?.health_plan?.fantasy_name }}</td>
                <td>{{  exception.increase_decrease }}</td>
                <td>{{ parseNumberToMoney(exception.unitary_value) }}</td>
                <td>
                    <div class="actions">
                        <toggle-button
                            :width="35"
                            v-model="exception.active"
                            :sync="true"
                            :height="22"
                            :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
                            @change="$event => $emit('updateActive', { exception, active: $event.value })"
                            v-b-tooltip.hover :title="exception.active ? 'Desativar exceção' : 'Ativar exceção'"
                        />
                        <Delete 
                            class="icon" 
                            v-b-tooltip.hover title="Excluir item"
                            @click="() => deleteItem(exception)"
                        />
                    </div>
                </td>
            </tr>
        </tbody>
    </b-table-simple>
</template>

<script>
import { parseNumberToMoney } from '@/utils/moneyHelper';

export default {
    components: {
        Ellipsis: () => import('@/components/General/Ellipsis'),   
        Check: () => import('@/components/General/Check'),
        Delete: () => import('@/assets/icons/delete.svg'),
        // Edit: () => import('@/assets/icons/edit.svg'),
    },
    props: {
        exceptions: {
            type: Array,
            default: []
        },
        selecteds: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            masterCheck: false
        }
    },
    methods: {
        parseNumberToMoney,
        parseDate(date, complete = false) {
            return this.moment(date).format(complete ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY')
        },        
        deleteItem(exception) {
            this.$swal({
                icon: 'question',
                html: `<div class="body-alert">Deseja realmente excluir esta exceção?</div>`,
                showCancelButton: true,
                confirmButtonColor: '#F63220',
                confirmButtonText: 'Confirmar',
                cancelButtonText: 'Cancelar',
                reverseButtons: true
            }).then(res => {
                if (res.isConfirmed) {
                    this.$emit('delete', exception)
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/table.scss';
.icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

</style>